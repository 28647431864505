import React, { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../@common/contexts/auth'
import { Flex } from 'antd'
import { PoweroffOutlined } from '@ant-design/icons';

const HomeLayout = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate();

  const logout = () => {
    auth.logout()
    navigate('/')
  }

  return (
    <>
      <Flex align='center' justify='space-between' style={{ paddingInline: 16, paddingBlock: 12 }}>
        <img style={{ width: '15rem' }} src={'../../../../assets/arena_logo.png'} />
        {/* <h3>Hi, {auth.user.username}👋</h3> */}

        <PoweroffOutlined onClick={logout} size={24} />
      </Flex>
      <Outlet />

    </>
  )
}

export default HomeLayout
